
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Sarabun';
    font-style: normal;
}

button,  input, select {
  outline: none;
  border: none;
}

p, h1, h2, h3, h4, h5, h6, label, hr {
  padding: 0;
  margin: 0;
  font-family: 'Livvic';
  font-style: normal;
  color: #222222;
}


input, select {
  padding: 10px;
  outline: none;
  border: none;
  color: #090909;
  background: #e9ecef;
  border: 2px solid #E0E0E0;
  border-radius: 5px;
}
